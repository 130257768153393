/* eslint-disable @next/next/no-page-custom-font */
import Head from "next/head";
import Script from "next/script";

interface SEOProps {
    description: string;
    title: string;
    image: string;
    url: string;
    hreflang?: boolean;
    structuredData?: any;
    structuredProductData?: any;
    link?: any;
    lang?: string;
    script_load?: string;
}

export default function SEO({
    description,
    title,
    image,
    url,
    hreflang,
    structuredData,
    structuredProductData,
    link,
    lang,
    script_load
}: SEOProps) {

    const returnCorrectHrefLinks = () => {
        const languages = ["en", "no", "da", "sv", "es"];
        const baseUrl = "https://runagain.com/";
    
        return (
            <>
                {/* Canonical link based on selected language */}
                <link rel="canonical" href={`${baseUrl}${lang === "en" ? "" : lang + (url != "" ? "/": "")}${url}`} />
    
                {/* Alternate links for all other languages */}
                {languages
                    .map(language => (
                        <link
                            key={language}
                            rel="alternate"
                            hrefLang={language}
                            href={`${baseUrl}${language === "en" ? "" : language + (url != "" ? "/": "")}${url}`}
                        />
                    ))}
            </>
        );
    };

    return (
        <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <title>{`${title} | RunAgain`}</title>
            <meta name="description" content={description} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content={"RunAgain"} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={"https://runagain.com/" + url} />
            <meta property="twitter:card" content={description} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="twitter:image" content={image} />
            {!hreflang && <link rel="canonical" href={"https://runagain.com/" + url} />}
            {hreflang && (
                returnCorrectHrefLinks()
            )}
            {structuredData && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
                    key="product-jsonld"
                />
            )}
            {structuredProductData && (
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredProductData) }}
                    key="product-jsonld-productdata"
                />
            )}
            {link && (
                <>
                    <link href={link} rel="stylesheet" />
                </>
            )}
            {script_load && (                
                    // eslint-disable-next-line @next/next/no-sync-scripts
                    <script id="stravaScript" src={script_load} />
            )}
        </Head>
    );
}
