
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import * as ROUTES from "@/constants/routes";
import { meta_image } from "@/Images";
import PrimaryBtn from "@/components/common/buttons/PrimaryBtn";
import useTranslation from "next-translate/useTranslation";
import { FC } from "react";
import { useRouter } from "next/router";
import { withFirebase } from "@/components/Firebase";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SEO from "@/components/common/seo/seo";
import withAuthUser from "@/helpers/authHandler/withAuthUser";
import { FIND_RUN } from "@/constants/routes";
import dynamic from "next/dynamic";
import DynamicLoader from "@/helpers/loader/dynamicLoader";
import LazyLoader from "@/helpers/loader/lazyLoader";
const LoadingComponent = () => <DynamicLoader width={"100%"} height={300}/>;
// import WelcomeHeader from "@/content/masterclass/components/welcomeHeader";
const WelcomeHeader = dynamic(() => import("@/content/masterclass/components/welcomeHeader"), {
    ssr: true,
});
const TicketRunsCarousel = dynamic(() => import("@/components/RecommendedComponents/ticketRuns"), {
    ssr: true,
});
const JoinCommunity = dynamic(() => import("@/content/masterclass/components/joinCommunity"), {
    ssr: true,
    // loading: LoadingComponent,
});
const CloseToYou = dynamic(() => import("@/components/closeToYou/closeToYou"), {
    ssr: false,
    loading: LoadingComponent,
});
const Participate = dynamic(() => import("@/content/frontPage/raAds/participate"), {
    ssr: false,
    loading: LoadingComponent,
});
const PremiumAd = dynamic(() => import("@/content/frontPage/raAds/premiumAd"), {
    ssr: false,
    loading: LoadingComponent,
});
const PartnerCarousel = dynamic(() => import("@/content/frontPage/partnerCarousel/partnerCarousel"), {
    ssr: false,
    loading: LoadingComponent,
});
const MarathonRunsCarousel = dynamic(() => import("@/components/RecommendedComponents/marathonRuns"), {
    ssr: false,
    loading: LoadingComponent,
});
const BlogAd = dynamic(() => import("@/content/frontPage/BlogAd/blogAd"), {
    ssr: false,
    loading: LoadingComponent,
});
const classes = {
    section: {
        py: 5,
    },
    sectionGray: {
        py: 5,
        backgroundColor: "#efefef",
    },
    sectionOrange: {
        py: 10,
        backgroundColor: "rgba(255, 134, 64, 0.1)",
    },
    sectionCarousel: {
        pt: 5,
        pb: 2,
    },
};
interface HomePageProps {
    firebase: any;
}
const Home: FC<HomePageProps> = ({ firebase }) => {
    const router = useRouter();
    const { t, lang } = useTranslation("translation");
    return (<>
            <SEO description={t("frontPage.headerTitle") + " " + t("frontPage.subTitle")} title={t("components.navigation.home")} image={meta_image.src} hreflang={true} lang={lang} url={""}/>

            <WelcomeHeader />
            <JoinCommunity />
            <Container maxWidth="lg" sx={classes.sectionCarousel}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">{t("frontPage.tickets")}</Typography>
                    <PrimaryBtn onClick={() => router.push({
            pathname: ROUTES.FIND_RUN,
            query: { tickets: true },
        })}>
                        {t("frontPage.seeAll")}
                    </PrimaryBtn>
                </Stack>
                <LazyLoader>
                    <TicketRunsCarousel />
                </LazyLoader>
            </Container>
            <LazyLoader>
                <Box py={5}>
                    <Container maxWidth="lg">
                        <PremiumAd />
                    </Container>
                </Box>
            </LazyLoader>

            <LazyLoader>
                <Container maxWidth="lg" sx={classes.sectionCarousel}>
                    <CloseToYou />
                </Container>

                <Container maxWidth="lg" sx={classes.section}>
                    <PartnerCarousel />
                </Container>
            </LazyLoader>

            <LazyLoader>
                <Container maxWidth="lg" sx={classes.sectionCarousel}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h4">{t("frontPage.partners")}</Typography>
                        <PrimaryBtn onClick={() => router.push({ pathname: FIND_RUN, query: { heilighted: true } })}>
                            {t("frontPage.seeAll")}
                        </PrimaryBtn>
                    </Stack>
                    <MarathonRunsCarousel />
                </Container>

                <Box sx={classes.sectionGray}>
                    <Container maxWidth="lg">
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h4" mb={2}>
                                {t("blogPage.title")}{" "}
                            </Typography>
                            <PrimaryBtn onClick={() => router.push(ROUTES.BLOG)}>
                                {t("frontPage.seeAll")}
                            </PrimaryBtn>
                        </Stack>
                        <BlogAd />
                    </Container>
                </Box>
                <Container maxWidth="lg" sx={{ pb: 6 }}>
                    <Participate />
                </Container>
            </LazyLoader>
        </>);
};
export default withAuthUser()(withFirebase(Home));
async function getStaticProps({ locale }: any) {
    return {
        props: {
            ...(await serverSideTranslations(locale, ["translation"])),
        },
    };
}

    async function __Next_Translate__getStaticProps__193c17a8795__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__193c17a8795__ as getStaticProps }
  