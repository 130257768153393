import { useEffect, useState, useRef } from 'react';

interface LazyLoaderProps {
  children: React.ReactNode;
}

const LazyLoader: React.FC<LazyLoaderProps> = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after it has been loaded
        }
      },
      { threshold: 0.05 } // You can tweak this threshold value
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return <div ref={ref}>{isVisible ? children : null}</div>;
};

export default LazyLoader;
