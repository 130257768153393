import { Box, Skeleton } from "@mui/material";
import { FC } from "react";

interface LoaderProps {
    width: string;
    height: number;
}

const DynamicLoader: FC<LoaderProps> = ({ width, height }): JSX.Element => {
    return (
        <Box sx={{p:2}}>

            <Skeleton variant="rectangular" width={width} height={height} animation="wave" />
        </Box>
);
};

export default DynamicLoader;
