
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React, { FC, ReactNode } from 'react';

/***** TYPES *****/
interface PrimaryBtnProps {
    children: ReactNode,
    onClick?: React.MouseEventHandler
    type?: "Submit" | "Reset",
    icon?: JSX.Element
    isLoading?: boolean,
    style?: object
};

/***** COMPONENT-FUNCTION *****/
const PrimaryBtn: FC<PrimaryBtnProps> = ({ children, onClick, type, icon, isLoading, style }): JSX.Element => {

    /*** Return statement ***/
    return (
        <Button
            variant="contained"
            color="primary"
            href=""
            onClick={onClick}
            disabled={isLoading}
            type={type}
            startIcon={icon}
            sx={{ display: "flex", alignItems: "center" }}
            style={style}
        >
            {children}
            {isLoading && <CircularProgress color='inherit' size={16} sx={{ ml: 1 }} />}
        </Button>
    );
};

export default PrimaryBtn;